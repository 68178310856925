import React,{Suspense} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Loading from './pages/Loading';
import './assets/css/style.css'
import {Routes, Route} from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Home = React.lazy(() => import('./pages/Home'))
const About = React.lazy(() => import('./pages/About'))
const Contact = React.lazy(() => import('./pages/Contact'))
const Forms = React.lazy(() => import('./pages/Forms'))
const PatientResources = React.lazy(() => import('./pages/PatientResources'))
const PrivacyPolicy = React.lazy(() => import('./pages/PrivacyPolicy'))
const Error = React.lazy(() => import('./pages/Error404'))


function App() {
  return (
    <>
      <Suspense fallback={<Loading/>}>
          <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact-us" element={<Contact />} />
              <Route path="/forms" element={<Forms />} />
              <Route path="/patient-resources" element={<PatientResources />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="*" element={<Error />} />
              
          </Routes>
          <ToastContainer
              position="bottom-left"
              autoClose={5000}
          />
      </Suspense>
    </>
  );
}

export default App;
