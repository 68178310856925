import React from 'react'
import { Spinner } from 'react-bootstrap'

const Loading = () => {
  return (
  <div id="splash-screen" className="splash-screen">
      <span>
        {/* spinner bootstrap */}
        <Spinner animation="border" style={{color: "#EF4160"}} variant="" />
      </span>
  </div>
  )
}

export default Loading